import { get, post,put,deleteRequest, service1 } from '../common/http';
import base from '../common/base';
import axios from 'axios' // 引用axios

const reviewBlock = {
      // 删除任务
      deleteReviewData(data){
        return deleteRequest(base.prefix + '/api/v1/speed/delPaintInfo', data,'arr')
    },
      // 清除任务记录
      delAssignments(data){
        return put(base.prefix + '/api/v1/answer/delAssignments', data)
    },
      // 阅卷进度老师查询
      getTeacherPaperProcess(data){
        return get(base.prefix + '/api/v1/monitor/topicList', data)
    },
      // 年级科目查询
      getGradeCourse(data){
        return get(base.prefix + '/api/v1/grade/commGrade', data)
    },
      // 阅卷监控列表
      getexamMonitor(data){
        return get(base.prefix + '/api/v1/monitor/markingList', data)
    },
      // 成绩发布
      scoreUpload(data){
        return get(base.prefix + '/api/v1/release', data)
    },
    // 回收站恢复
    recyleBinRestore(data){
      return post(base.prefix + '/api/v1/scanned/historyReduction', data)
  },
      // 回收站列表
      getRecyleBinList(data){
        return get(base.prefix + '/api/v1/scanned/historyList', data)
    },
    // 获取阅卷进度列表
    updatePaperStatus(data){
      return put(base.prefix + '/api/v1/monitor/updateMark', data)
  },
      // 获取阅卷进度列表
      getReviewPaperProcess(data){
        return get(base.prefix + '/api/v1/monitor/monitorList', data)
    },
      // 生成阅卷任务
      generateReviewTask(data){
        return post(base.prefix + '/api/v1/answer/addAssignments', data)
    },
      //临时用户导出
      tempUserExport(data){
        const url = base.prefix + '/api/v1/answer/exportTeachInfo';
          return new Promise((resolve, reject) => {
            service1.get(url, { params: data, responseType: 'blob' }).then(
                response => {
                    resolve(response)
                },
                err => {
                    reject(err)
                }
            )
        })
    },
      // 设置科组长
      addTempUser(data){
        return get(base.prefix + '/api/v1/answer/addTeach', data)
    },
      // 设置科组长
      setCourseLeader(data){
        return post(base.prefix + '/api/v1/answer/addLead', data)
    },
      // 获取科组长
      getCourseLeader(data){
        return get(base.prefix + '/api/v1/answer/leaderList', data)
    },
      // 删除任务
      deleteTask(data){
        return deleteRequest(base.prefix + '/api/v1/answer/delReader', data,'arr')
    },
    // 任务分配提交接口
    addTask(data){
      return post(base.prefix + '/api/v1/answer/addReader', data)
    },
    //任务分配获取系统/临时用户列表
    getSystemUserList(data){
      return get(base.prefix + '/api/v1/answer/userList', data)
    },
    //任务分配获取任务列表
    getAssignWorkList(data){
      return get(base.prefix + '/api/v1/answer/paintings', data)
    },
    //任务分配获取系统/临时用户列表
    objectiveCheck(data){
      return post(base.prefix + '/api/v1/scanned/updateObject', data)
    },
  //获取试卷信息
  getPaperInfo(id){
    return get(base.prefix + '/api/v1/myExamCourse/school-exam-course-relation-infos/' + id)
  },
  // 新增批阅块
  addNewReviewBlock(data){
    return post(base.prefix + '/api/v1/school-answer-paintings', data)
  },
    // 获取批阅块列表
    getReviewBlockList(data){
        return get(base.prefix + '/api/v1/school-answer-paintings', data)
    },
    // 编辑批阅块
    editReviewBlock(data){
        return post(base.prefix + '/api/v1/school-answer-paintings-update', data)
    },
    // 删除批阅块
    deleteReviewBlock(data){
        return deleteRequest(base.prefix + '/api/v1/school-answer-paintings', data,'arr')
    },
      //获取采分点列表
  getSamplingPointList(data){
    return get(base.prefix + '/api/v1/school-answer-samplings', data)
  },
  //新增采分点
  addSamplingPoint(data){
    return post(base.prefix + '/api/v1/school-answer-samplings', data)
  },
  //删除采分点
  deleteSamplingPoint(data){
    return deleteRequest(base.prefix + '/api/v1/school-answer-samplings', data, "arr")
  },
  //编辑采分点
  editSamplingPoint(data){
    return put(base.prefix + '/api/v1/school-answer-samplings-update', data)
  },
  //获取批阅块详情
  getReviewBlockDetail(id){
    return get(base.prefix + '/api/v1/school-answer-paintings/' +  id)
  },
    //获取标准答案录入列表
    getStandardAnswerEnterList(data){
      return get(base.prefix + '/api/v1/school-answer-enters',data)
    },
    //编辑标准答案录入
    editStandardAnswerEnter(data){
      return post(base.prefix + '/api/v1/school-answer-enters-update',data)
    },
    //获取标准答案录入列表
    getStandardAnswerScore(data){
      return get(base.prefix + '/api/v1/calculateScore',data)
    },
    //获取知识点列表
    getKnowledgePointList(data){
      return get(base.prefix + '/api/v1/school-knowledge-pointss',data)
    },
    //编辑知识点
    editKnowledgePoint(data){
      return put(base.prefix + '/api/v1/school-knowledge-pointss-update',data)
    },
    //标准答案导出
    standardAnswerExport(data){
      const url = base.prefix + '/api/v1/standardAnswerExport';
        return new Promise((resolve, reject) => {
          service1.post(url, data, {responseType: 'blob' }).then(
              response => {
                  resolve(response)
              },
              err => {
                  reject(err)
              }
          )
      })
  },
    //导入标准答案
    importStandardAnswer(data){
      const url = base.prefix + `/api/v1/standardAnswerImport`
      return new Promise((resolve, reject) => {
        service1.post(url, data, { headers: { 'Content-Type': 'multipart/form-data' }}).then(
          response => {
            resolve(response.data)
          },
          err => {
            reject(err)
          }
        )
      })
    },
    //导入知识点
    importKnowledgePotin(data){
      const url = base.prefix + `/api/v1/knowledgePointssImport`
      return new Promise((resolve, reject) => {
        service1.post(url, data, { headers: { 'Content-Type': 'multipart/form-data' }}).then(
          response => {
            resolve(response.data)
          },
          err => {
            reject(err)
          }
        )
      })
    },
    //知识点导出
    exportKnowledgePotin(data){
      const url = base.prefix + '/api/v1/knowledgePointssExport';
      return new Promise((resolve, reject) => {
        service1.post(url, data, {responseType: 'blob' }).then(
            response => {
                resolve(response)
            },
            err => {
                reject(err)
            }
        )
    })
    },
}

export default reviewBlock