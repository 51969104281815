import {
  getToken,
  removeToken,
  removeMenu
} from '@/utils/auth'
import router, {
  resetRouter
} from '@/router'
import user from '@/http/api/user'

const state = {
  token: getToken(),
  name: '',
  avatar: '',
  userName: '',
  roleId: '',
  buttons: [],
  userInfo:{},
  userInfoLogin:{},
}

const mutations = {

  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_userName: (state, userName) => {
    state.userName = userName
  },
  SET_USEAR_INFO:(state, userInfo)=>{
    state.userInfo = userInfo
  },
  SET_USEAR_INFOLOGIN:(state, userInfoLogin)=>{
    state.userInfoLogin = userInfoLogin
  },
  // roleId orderMgmt加权限
  SET_roleId: (state, roleId) => {
    state.roleId = roleId
  },
  SET_BUTTONS: (state, buttons) => {
    state.buttons = buttons
  }
}
let logoutInProgress = false;
const actions = {
  SET_TOKEN: ({
    commit
  }, token) => {
    commit('SET_TOKEN', token)
  },
  // user login
  login({
    commit
  }, userInfo) {
    console.log('action')
    const {
      username,
      password
    } = userInfo
    return new Promise((resolve, reject) => {
      console.log('action login')
      user.login({
        loginId: username.trim(),
        password: password
      }).then(response => {
        console.log('action login response')
        const {
          data
        } = response
        // console.log('data.accessToken' + data.accessToken)
        commit('SET_TOKEN', data.accessToken)
        // setToken(data.accessToken)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // user logout
  logout({commit}) {
    if (logoutInProgress) {
      return Promise.resolve();
    }
    logoutInProgress = true;
    return new Promise((resolve, reject) => {
      user.logout().then(response => {
        if(response.code==401){
          console.log('已掉线')
        }
        router.push('/login')
        commit('SET_TOKEN', '')
        removeToken()
        resolve()
       
      }).catch(error => {
        reject(error)
      }).finally(() => {
        logoutInProgress = false;
      });
    })
   
  },
  getInfo({commit}){
    return new Promise((resolve, reject) => {
    //   debugger
      user.getUserInfo().then((res) => {
        if(res.code == 200){
          let {data} = res;
          state.userInfo = data
          commit('SET_USEAR_INFO',data)
          commit('SET_NAME', data.username)
          
          resolve()
        }
      },()=>{
        reject()
      })
    })
  },
  // remove token
  resetToken({commit}){
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      removeToken()
      resolve()
    })
  },
}
const getters = {
  userInfoLogin: (state) => state.userInfoLogin
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
