<template>
  <div id="app" ref="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
    
  },
  mounted() {
      // autofit.init({
      //     dh: 900,
      //     dw: 1440,
      //     el:"body",
      //     resize: true
      // })
    },
}
</script>

<style>
#app{
  height: 100%;
  width:100%;
}
</style>
