
const state = {
  drawType:1, //1:鼠标模式 2:定位区 3:标题区 4:学号区 5:姓名区 6:缺考区 7:AB卷区 8:客观题区 9:选做题区 10:主观题
  chunkData:[], //当前页模版切块数据
  nowPageChunkData:[], //当前页所有切块
  allChunkData:[],//所有切块数据
  page:1, //当前页
  allPages:1,//共几页
  pageImgs:[], //当前模版所有图片图片
  nowPageImgUrl:'',//当前页试卷图片地址
  chuncksAreaNums:[],//每个块区个数
  cardType:1, //纸张类型 
  examTempDetail:{},//考试模板详情数据
  examStateStr:localStorage.getItem('examStateStr') || '',//考试状态列表 完成状态

}

const mutations = {

  // 设置考试模板详情数据
  setExamTempDetail: (state, data) => {
    state.examTempDetail = data;
    console.log("state值已经更新",state.examTempDetail);
    if(data.showExamStatus!=null)
    {
      state.examStateStr=data.showExamStatus;
      localStorage.setItem("examStateStr", state.examStateStr);//持久化存储
    }
    else
    {
      state.examStateStr="";
      localStorage.setItem("examStateStr", '');//持久化存储
    }
    
    localStorage.setItem("examTempDetail", JSON.stringify(data));//持久化存储
  },
  //清除考试模板详情数据
  Clear_ExamTempDetail(state){
    state.examTempDetail = {}; 
    // localStorage.removeItem("examTempDetail"); //清除存储
  },
  SET_DrawType: (state, data) => {
    state.drawType = data;
  },
  SET_allPages: (state, data) => {
    state.allPages = data;
  },
  SET_ChunkData: (state) => {
    //当前页切块数据
    // state.chunkData = state.allChunkData;
    if(state.drawType == 1){
      // state.chunkData = state.allChunkData.filter(item => item.page == state.page);
      state.chunkData = state.allChunkData;
    }else{
      state.chunkData = state.allChunkData.filter(item => item.page == state.page && state.drawType == item.blockArea);
    }
    
  },
  SET_nowPageChunkData: (state) => {
    //当前页切块数据
    state.nowPageChunkData = state.allChunkData.filter(item => item.page == state.page);
  },
  SET_ChuncksAreaNums: (state,data) => {
    //切块区域个数
    state.chuncksAreaNums = data;
  },
  SET_PageImgs: (state,data) => {
    //当前页图片地址
    state.pageImgs = data;
  },
  Set_nowPageImgUrl:(state) => {
    //当前页图片地址
    state.nowPageImgUrl = state.pageImgs[state.page-1];
  },

  pushDrawChunkData: (state, data) => {
    state.chunkData.push(data);
  },
  deleteDrawChunkData: (state, id) => {
    let index = state.chunkData.findIndex(item => item.id === id);
    let index1 = state.allChunkData.findIndex(item => item.id === id);
    state.chunkData.splice(index,1);
    state.allChunkData.splice(index1,1);
  },
  clearDrawChunkData: (state) => {
    state.chunkData = [];
  },
  SET_AllChunkData: (state, data) => {
    state.allChunkData = data;
  },
  SET_page: (state, data) => {
    state.page = data;
  },
  SET_cardType: (state, data) => {
    state.cardType = data;
  },
  resetTemplateData: (state) => {
    state.page = 1;
    state.allPages = 1;
    state.pageImgs = [];
    state.nowPageImgUrl = '';
    state.chuncksAreaNums = [];
    state.chunkData = [];
    state.nowPageChunkData = [];
    state.allChunkData = [];
    state.drawType = 1;
    state.cardType = 2;

  },
}

const actions = {
  //鼠标复位
  mouseReset({commit}){
    commit('SET_DrawType',1);
    commit('SET_ChunkData')
  },

  //异步加载考试详情数据
  async loadExamTempDetail({commit}){
    try {
      const jsonString = localStorage.getItem('examTempDetail');
      if (jsonString) {
        const data = JSON.parse(jsonString);
        commit('setExamTempDetail', data);
        
      } else {
        // 从服务器获取数据  暂不使用
        // const response = await fetch('https://api.example.com/examTempDetail');
        // const data = await response.json();
        // commit('setExamTempDetail', data);
      }
    } catch (error) {
      console.error('加载考试详情数据时出错:', error);
    }
    

  },

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}