import { get, post, put, deleteRequest } from '../common/http';
// import request from '../request';
import base from '../common/base';
import store from '@/store'
const exam = {
  // 获取考试类型
  getExamTypes(data) {
    return get(base.prefix + '/api/v1/exam/examTypeInfos', data)
  },
  // 获取考试列表
  getExamList(data) {
    return get(base.prefix + '/api/v1/myExam/listExamByPage', data)
  },
  //新增多科或单科
  insertExam(data){
    return post(base.prefix + '/api/v1/myExam/insertExam', data)
  },
  //新增单科
  insertSimpleCourseExam(data){
    return post(base.prefix + '/api/v1/myExam/insertSubject',data)
  },
  //编辑多科
  updateExam(data){
    return post(base.prefix + '/api/v1/myExam/updateExam', data)
  },
  //编辑单科
  updateSimpleCourseExam(data){
    return post(base.prefix + '/api/v1/myExam/updateExamMany',data)
  },
  //添加/更新考试
  insertOrUpdateExam(data) {
    return post(base.prefix + '/api/v1/exam/insert', data)
  },
  //删除多科考试
  deleteSingleExam(data) {
    return post(base.prefix + '/api/v1/myExam/deleteExamById', data)
  },
  //获取科目考试详细
  getExamDetail(data) {
    return get(base.prefix + '/api/v1/myExamCourse/school-exam-course-relation-infos', data)
  },  
  //获取负责人列表
  getExamManagerList(data) {
    return get(base.prefix + '/api/v1/teach/schoolTeacherInfo/school-teacher-infos-list', data)
  },

  //oss 上传base64图片
  uploadImg(data) {
    return post(base.prefix + '/api/v1/teach/oss/file/oss/upload_files', data)
  },
  saveCardTemplate(data) {
    return post(base.prefix + '/api/v1/myExamCourse/updateExamCourse', data)
  },
  getCardTemplateDetail(data) {
    return get(base.prefix + `/api/v1/myExamCourse/school-exam-course-relation-infos/${data.examId}`)
  },
  //新增切块
  insertExamCourse(data) {
    return post(base.prefix + '/api/v1/school-answer-positionings', data)
  },
  //编辑切块
  updateExamCourse(data) {
    return put(base.prefix + `/api/v1/school-answer-positionings/${data.id}`)
  },
  // 删除切块
  deleteExamCourse(data) {
    return deleteRequest(base.prefix + `/api/v1/school-answer-positionings`, data, 'arr')
  },
  //更新模版状态
  updateExamCourseStatus(data) {
    // return post(base.prefix + '/api/v1/myExamCourse/updateExamCourseStatus', data)
  },
  //获取批阅块列表
  getExamCourseList(data) {
    return get(base.prefix + `/api/v1/school-answer-paintings`, data)
  },

  //更新我的考试 目标状态
  updateShowExamStatus(stateNumber){
    let stateStr=store.state.makeTemplate.examStateStr || '';
    let newStateStr='';
    
    if(stateStr=='')
    {
        newStateStr=stateNumber;
    }
    else
    {
      //更新完成之前 判断是否已存在
      if(stateStr.includes(stateNumber))
      {
          // 存在
          newStateStr=stateStr;
      }
      else
      {   
          //不存在添加
         newStateStr=stateStr+','+stateNumber;
      }
        
    }
    let param={
      id:store.state.makeTemplate.examTempDetail.id,
      showExamStatus:newStateStr,//1,2,3
    };
    return post(base.prefix+'/api/v1/myExamCourse/updateShowExamStatus',param).then(res=>{
      console.log("打印js方法里的返回",res);
      localStorage.setItem("examStateStr", newStateStr);//更新
      store.state.makeTemplate.examStateStr=newStateStr;//更新state
      return res;
    }).catch(error=>{

      return error;
    })
  },

  //查询我的考试知识点分组列表
  getExamKnowledgeGroupList(param) {
    return get(base.prefix + `/api/v1/school-dicts`, param)
  },

  //新增我的考试知识点分组
  addExamKnowledge(param) {
    return post(base.prefix + `/api/v1/school-dicts`, param)
  },

  //编辑我的考试知识点分组信息
  updateExamKnowledge(id,param) {
    return put(base.prefix + `/api/v1/school-dicts/${id}`, param)
  },
  //删除我的考试知识点分组
  deleteExamKnowledgeGroup(param) {
    return deleteRequest(base.prefix + `/api/v1/school-dicts`, param,'arr')
  },

  //获取我的历史处理列表 已处理列表
  getHistoryList(param) {
    return get(base.prefix + `/api/v1/scanned/historyList`, param)
  },
}
export default exam