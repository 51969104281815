import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import VXETable from 'vxe-table'
// import '@/permission'

import store from './store'
import router from './router'
import axios from 'axios'
import api from './http/api'
import './http/common/base'
import {debounce} from "./utils/common";

import * as echarts from 'echarts'
import  particles from "particles.js"
import '@/styles/element-variables.scss'
// import 'element-ui/lib/theme-chalk/index.css';
import 'vxe-table/lib/style.css'
import '@/styles/common.scss'
// import CKEditor from '@ckeditor/ckeditor5-vue2';
// import CKEditor from 'ckeditor4-vue';
// Vue.use(CKEditor)
import InfiniteLoading from 'vue-infinite-loading';
import lottie from 'vue-lottie';

import getDpi from '@/utils/global.js'
Vue.prototype.$getDpi = getDpi;
// px2rem 自适应
// import './plugins/lib-flexible/flexible'
Vue.use(InfiniteLoading);
Vue.component('lottie', lottie);
const _ResizeObserver = window.ResizeObserver;
ElementUI.Dialog.props.closeOnClickModal.default = false
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
	constructor(callback) {
		callback = debounce(callback, 16);
		super(callback);
	}
};
// Vue.use(CKEditor)

Vue.use(particles)
Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(VXETable)
Vue.prototype.$axios = axios
Vue.prototype.$api = api
Vue.prototype.$echarts = echarts
const VUE_APP_VERSION = require('../public/version.json').version
const vers = window.localStorage.getItem("appVersion");
if(VUE_APP_VERSION != vers){
  localStorage.clear()
  window.localStorage.setItem("appVersion", VUE_APP_VERSION);
  location.reload()
}
new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App),
})
