/* eslint-disable */
import axios from 'axios' // 引用axios
import {
  getToken,getAdminToken
} from '@/utils/auth'
import store from '@/store'
import router from '@/router'

import { Loading,Message } from 'element-ui';
const service1 = axios.create({
  timeout: 120000
});

const service2 = axios.create({
  timeout: 120000
});

var loading = null; // loading
/**
 * @param {Boolean} showLoading 是否显示loading
 * @param {string} loadingText 文案
 */
function showLoading(config) {
	let data = config.method == 'get' ? config.params : config.data;
	if(data && data.showLoading) {
		loading = Loading.service({
		  lock: true,
		  text: data.loadingText || 'Loading...',
		  spinner: 'el-icon-loading',
		  background: 'rgba(0, 0, 0, 0.7)'
		});
		delete data.showLoading;
		if(data.loadingText) delete data.loadingText;
	}
}
function hideLoading() {
	if(loading) loading.close();
}

// axios 配置
// axios.defaults.timeout = 120000 //设置接口响应时间
service1.interceptors.request.use(
  config => {
	showLoading(config) // loading start
    // do something before request is sent
    if (store.getters.token) {
      config.headers['Authorization'] = getToken()
      
    }
    // 设置请求头
    // if(!config.headers["content-type"]) { // 如果没有设置请求头
    //   if(config.method === 'post') {
    //     config.headers["content-type"] = "application/x-www-form-urlencoded"; // post 请求
    //     config.data = qs.stringify(config.data); // 序列化,比如表单数据
    //   } else {
    //     config.headers["content-type"] = "application/json"; // 默认类型
    //   }
    // }
    return config
  }
)
service2.interceptors.request.use(
  config => {
    showLoading(config); // loading start
    if (store.getters.adminToken) {
      config.headers['Authorization'] = getAdminToken();
    }
    return config;
  }
);

// // response interceptor
service1.interceptors.response.use(
  response => {
	hideLoading(); // loading end
    const res = response
    if (res.config.responseType == "blob") {
      return res;
    }
    // if the custom code is not 20000, it is judged as an error.
    if (res.data.code) {
      if (res.data.code >= 400) {
        Message({message: res.data.msg,type: 'warning'})
      }
      if (res.data.code === 401) {
        store.dispatch("user/logout").then(() => {
          // router.push('/login')
        }).catch(err => {
          console.log(err)
        })
      }
      return Promise.resolve(res)
    } else {
      return Promise.reject(res)
    }
  },
  error => {
    console.log('err----' + error) // for debug
    
    if(error.response.status == 500){
      Message({message: '网络请求超时，请重试！',
      type: 'warning'})
    }
    
    return Promise.reject(error)
  }
)
service2.interceptors.response.use(
  response => {
	hideLoading(); // loading end
    const res = response
    if (res.config.responseType == "blob") {
      return res;
    }
    // if the custom code is not 20000, it is judged as an error.
    if (res.data.code) {
      if (res.data.code >= 400) {
        Message({message: res.data.msg,type: 'warning'})
      }
      if (res.data.code === 401) {
        store.dispatch("adminUser/adminLogout").then(() => {
          // router.push('/login')
        }).catch(err => {
          console.log(err)
        })
      }
      return Promise.resolve(res)
    } else {
      return Promise.reject(res)
    }
  },
  error => {
    console.log('err----' + error) // for debug
    
    if(error.response.status == 500){
      Message({message: '网络请求超时，请重试！',
      type: 'warning'})
    }
    
    return Promise.reject(error)
  }
)
export { service1, service2 };

/**
 * fetch 请求方法
 * @param url
 * @param params
 * @returns {Promise}
 */
export function get(url, params = {}, useService1 = false) {
  const service = useService1 ? service2 : service1;
  return new Promise((resolve, reject) => {
    service.get(url, {
        params: {
          ...params
        }
      })
      .then(response => {
        resolve(response.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

/**
 * post 请求方法
 * @param url
 * @param data
 * @returns {Promise}
 */
export function post(url, data = {}, useService1 = false) {
  const service = useService1 ? service2 : service1;
  return new Promise((resolve, reject) => {
    service.post(url, data).then(
      response => {
        resolve(response.data)
      },
      err=>{
        reject(err)
      }
    )
  })
}

/**
 * patch 方法封装
 * @param url
 * @param data
 * @returns {Promise}
 */
export function patch(url, data = {},useService1 = false) {
  const service = useService1 ? service2 : service1;
  return new Promise((resolve, reject) => {
    service.patch(url, data).then(
      response => {
        resolve(response.data)
      },
      err => {
        reject(err)
      }
    )
  })
}

/**
 * put 方法封装
 * @param url
 * @param data
 * @returns {Promise}
 */
export function put(url, data = {},useService1 = false) {
  const service = useService1 ? service2 : service1;
  return new Promise((resolve, reject) => {
    service.put(url, data).then(
      response => {
        resolve(response.data)
      },
      err => {
        reject(err)
      }
    )
  })

}

/**
 * delete 方法封装
 * @param url
 * @param params
 * @returns {Promise}
 */
export function deleteRequest(url, params = {},type,useService1 = false){
  const service = useService1 ? service2 : service1;
  return new Promise((resolve, reject) => {
    if(type=='arr'){
      service.delete(url, {
        data: {
          ...params
        }
      }).then(
        response => {
          resolve(response.data)
        },
        err => {
          reject(err)
        }
      )
    } else if(type == 'array'){
      service.delete(url, {
        data: [...params]
      }).then(
        response => {
          resolve(response.data)
        },
        err => {
          reject(err)
        }
      )
    }else{
      service.delete(url, {
        params: {
          ...params
        }
      }).then(
        response => {
          resolve(response.data)
        },
        err => {
          reject(err)
        }
      )
    }
  })

}
